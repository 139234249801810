import React, { Component } from "react";
import fetch from "node-fetch";
import { Auth } from "aws-amplify";
export default class ListData extends Component<{}, { items: any }> {
  constructor(props: any) {
    super(props);
    this.state = { items: [] };
  }
  async componentDidMount() {
    const token = await (await Auth.currentSession()).getAccessToken();
    const jwtToken = await token.getJwtToken();
    const result = await fetch(
      "https://54m3lij2q5.execute-api.eu-west-1.amazonaws.com/prod/x",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    try {
      const x = await result.json();
      console.log(x.x.Items);
      this.setState({ items: x.x.Items });
    } catch (error) {
      console.log(error);
    }
  }
  ListItem = (items: any) => {
    console.log({ z: items });
    return items.map((item: any) => (
      <tr key={item.id}>
        <td>{item.id}</td>
      </tr>
    ));
  };
  render() {
    const { items } = this.state;
    console.log({ items, len: items.length });
    if (items.length === 0) {
      return <></>;
    } else {
      console.log({ xx: items });
      return (
        <table>
          <thead>
            <tr>
              <th>C1</th>
              <th>C2</th>
            </tr>
          </thead>
          <tbody>{this.ListItem(items)}</tbody>
        </table>
      );
    }
  }
}
