import React from "react";

const About = () => {
  return (
    <>
      <h1>About</h1>
    </>
  );
};

export default About;
