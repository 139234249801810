import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import ListData from "./Pages/ListData";
import Contact from "./Pages/Contact";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import About from "./Pages/About";
import { CreateUser } from "./Pages/CreateUser/CreateUser";

Amplify.configure(awsconfig);
function App() {
  Auth.currentSession().then((res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    //You can print them to see the full objects
    console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
    console.log(`myJwt: ${jwt}`);
  });
  return (
    <AmplifyAuthenticator>
      <div className="App">
        <AmplifySignOut />
        <Router>
          <div>
            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/about">
              <About />
            </Route>
            <Route path="/createuser">
              <CreateUser />
            </Route>
            <Route exact path="/">
              <ListData />
            </Route>
          </div>
        </Router>
      </div>
    </AmplifyAuthenticator>
  );
}

export default App;
