/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:15e1229b-aac0-4ffc-9be8-9c5b46cf0d3f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_8nYqiwpAb",
    "aws_user_pools_web_client_id": "3ul6nt76g85ea1hk12nim4l1k6",
    "oauth": {}
};


export default awsmobile;
