import * as yup from "yup";
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  plotNumber: yup.number().required().positive().integer(),
  email: yup.string().email().required(),
});
export const validate = async (
  firstName: string,
  lastName: string,
  plotNumber: string,
  email: string
): Promise<any> => {
  console.log(firstName, lastName, plotNumber, email);
  const results = schema
    .validate(
      {
        firstName,
        lastName,
        plotNumber,
        email,
      },
      {
        abortEarly: false,
      }
    )
    .then((result) => {
      return [];
    })
    .catch((err) => {
      const errors = err.inner.map((error: any) => ({
        field: error.path,
        message: error.message,
      }));
      console.log(errors);
      return errors;
    });
  console.log(results);
  return results;
};
