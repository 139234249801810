import * as React from "react";

export interface IAppProps {
  validationRef: string;
  errors: any;
}

export function ValidationError(props: IAppProps) {
  return (
    <>
      <label
        hidden={
          props.errors &&
          !props.errors.find(
            (error: any) => error.field === props.validationRef
          )
        }
      >
        *
      </label>
    </>
  );
}
