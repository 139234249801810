import React, { useState } from "react";
import { Button, TextField, Grid } from "@material-ui/core";

import { ValidationError } from "../Shared/ValidationError";
import fetch from "node-fetch";
import { Auth } from "aws-amplify";
import { validate } from "./Components/validate";
export interface IAppProps {}
export function CreateUser(props: IAppProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [plotNumber, setPlotNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  const saveUser = async () => {
    console.log("Saving user");
    if (errors.length > 0) {
      console.log({ errors });
      return;
    } else {
      const token = await (await Auth.currentSession()).getAccessToken();
      const jwtToken = await token.getJwtToken();
      const requestBody = {
        firstName,
        lastName,
        plotNumber,
        email,
      };
      try {
        console.log({ xa: errors.length });
        if (errors.length > 0) {
          return;
        }
        const requestHeaders: any = new Headers({
          // Accept: 'application/json',
          // Content-Type: 'application/json',
          //   Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
          //  Content-Type: "application/json",
        });
        requestHeaders.set("Content-Type", "application/json");
        const result = await fetch(
          "https://54m3lij2q5.execute-api.eu-west-1.amazonaws.com/prod/x",
          {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(requestBody),
          }
        );
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            id="standard-basic"
            label="FirstName"
            value={firstName}
            onChange={async (e) => {
              setFirstName(e.target.value);
              const errors = await validate(
                firstName,
                lastName,
                plotNumber,
                email
              );
              console.log({ z: errors });
              setErrors(errors);
            }}
            variant="outlined"
          />
          <ValidationError validationRef="firstName" errors={errors} />
          {/* <label
            hidden={
              errors.length > 0 &&
              !errors.find((error: any) => error.field === "firstName")
            }
          >
            *
          </label> */}
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="standard-basic"
            label="LastName"
            variant="outlined"
            value={lastName}
            onChange={async (e) => {
              setLastName(e.target.value);
              const errors = await validate(
                firstName,
                lastName,
                plotNumber,
                email
              );
              setErrors(errors);
            }}
          />
          <ValidationError validationRef="lastName" errors={errors} />
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="standard-basic"
            label="Plot Number"
            variant="outlined"
            value={plotNumber}
            onChange={async (e) => {
              setPlotNumber(e.target.value);
              const errors = await validate(
                firstName,
                lastName,
                plotNumber,
                email
              );
              setErrors(errors);
            }}
          />
          <ValidationError validationRef="plotNumber" errors={errors} />
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="standard-basic"
            label="Email"
            variant="outlined"
            value={email}
            onChange={async (e) => {
              setEmail(e.target.value);
              const errors = await validate(
                firstName,
                lastName,
                plotNumber,
                email
              );
              setErrors(errors);
            }}
          />
          <ValidationError validationRef="email" errors={errors} />
        </Grid>
        <Button onClick={saveUser} variant="contained" color="primary">
          Create User
        </Button>
      </Grid>
    </div>
  );
}
